
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '../../atomic/atoms/Button/Button.vue';
import Input from '../../atomic/atoms/Input/Input.vue';
import SingleSelectInputSuggestion from '../../atomic/molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import ImageUploader from '../../atomic/organisms/Image-Uploader/Image-Uploader.vue';
import MainTemplate from '../../atomic/templates/MainTemplate/MainTemplate.vue';
import { FormBuilder, FormControl } from '../../utilities/Forms';
import { IDropdownOption } from '../../utilities/Interfaces/form.interfaces';
import { Validators } from '../../utilities/Validators/Validators';
import Tooltip from '../../atomic/atoms/Tooltip/Tooltip.vue';
import Divider from '../../atomic/atoms/Divider/Divider.vue';
import Loader from '../../atomic/atoms/Loader/Loader.vue';
import jimp from 'jimp';
import Jimp from 'jimp';
import { IAccess, IBrand, IStore } from '../../../../../interfaces/src/v2';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import DatePicker from '@/atomic/molecules/DatePicker/DatePicker.vue';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';

interface IUploadedImage {
  url: string;
}

export default defineComponent({
  components: {
    'main-template': MainTemplate,
    'arc-input': Input,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-image-uploader': ImageUploader,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-divider': Divider,
    'arc-loader': Loader,
    'arc-multi-select-input': MultiSelectInput,
    'arc-checkbox': Checkbox,
    'arc-date-picker': DatePicker,
  },
  computed: {
    ...mapGetters([
      'user',
      'languageOptions',
      'isAuthenticated',
      'permissionTemplateOptions',
      'permissionTemplates',
      'metaEmailTemplates',
      'ccUsersOptions',
      'bccUsersOptions',
    ]),
    passwordValid(): boolean {
      let valid = false;
      if (
        this.passwordChangesMade &&
        this.passwordFormData.errors.newPassword.status === 'success' &&
        this.passwordFormData.errors.confirmPassword.status === 'success' &&
        this.passwordFormData.controls.newPassword.value === this.passwordFormData.controls.confirmPassword.value &&
        this.passwordFormData.controls.newPassword.value !== '' &&
        this.passwordFormData.controls.confirmPassword.value !== ''
      ) {
        valid = true;
      }
      return valid;
    },
    minDate(): Date {
      return new Date();
    },
  },
  async mounted(): Promise<void> {
    this.loadLanguageOptions();
    this.loadPermissionTemplateOptions();
    this.fetchPermissionTemplates();
    if (this.user) {
      this.setForm();
      this.getEmailTemplates('user');
      this.loadCcUsers({ companyId: this.user.companyId, excludeUserId: this.user.userId });
      this.loadBccUsers({ companyId: this.user.companyId, excludeUserId: this.user.userId });
    }
    setTimeout(() => {
    }, 2000);
  },
  data(): {
    loaded: boolean;
    formData: any;
    passwordFormData: any;
    languageValue: IDropdownOption | null;
    allowShowOptions: boolean;
    changesMade: boolean;
    passwordChangesMade: boolean;
    saving: boolean;
    resetting: boolean;
    profileImage: IUploadedImage[];
    profileImageUploading: boolean;
    signature: IUploadedImage[];
    signatureUploading: boolean;
    inputNewType: string;
    inputConfirmType: string;
    emailTemplates: any[];
    snooze: boolean;
    redirectTo: IDropdownOption | null;
    redirectEndDate: Date | null;
  } {
    return {
      loaded: false,
      formData: null,
      passwordFormData: null,
      languageValue: null,
      allowShowOptions: false,
      changesMade: false,
      passwordChangesMade: false,
      saving: false,
      resetting: false,
      profileImage: [],
      profileImageUploading: false,
      signature: [],
      signatureUploading: false,
      inputNewType: 'password',
      inputConfirmType: 'password',
      emailTemplates: [],
      snooze: false,
      redirectTo: null,
      redirectEndDate: null,
    };
  },
  methods: {
    ...mapActions([
      'loadLanguageOptions',
      'updateUser',
      'uploadImage',
      'updateCurrentUser',
      'authenticate',
      'loadPermissionTemplateOptions',
      'fetchPermissionTemplates',
      'saveUserEmailTemplate',
      'getEmailTemplates',
      'loadCcUsers',
      'loadBccUsers',
      'saveUserEmailPreference',
    ]),
    setForm(): void {
      // initializers
      let firstName = '';
      let lastName = '';
      let mobileNumber = '';
      let officeNumber = '';
      let title = '';
      let language = null;
      // set values if editing existing campaign
      if (this.user) {
        firstName = this.user?.firstName;
        lastName = this.user?.lastName;
        mobileNumber = this.user?.mobileNumber;
        officeNumber = this.user?.officeNumber;
        title = this.user?.title;
        language = this.user?.languageId;
        this.profileImage = [
          {
            url: this.user?.profileImageUrl,
          },
        ];
        this.signature = [
          {
            url: this.user?.signatureUrl,
          },
        ];
        this.languageOptions.forEach((item: IDropdownOption) => {
          if (this.user?.languageId === item?.value) {
            this.languageValue = item;
          }
        });
        this.snooze = this.user?.emailPreference?.snooze;
        this.redirectTo = this.user?.emailPreference?.redirectTo;
        this.redirectEndDate = this.user?.emailPreference?.redirectEndDate;
      }
      // set form
      this.formData = new FormBuilder({
        firstName: new FormControl(firstName, [Validators.required]),
        lastName: new FormControl(lastName),
        mobileNumber: new FormControl(mobileNumber),
        officeNumber: new FormControl(officeNumber),
        title: new FormControl(title),
        language: new FormControl(language, [Validators.required]),
      });
      this.passwordFormData = new FormBuilder({
        newPassword: new FormControl('', [Validators.password, Validators.required]),
        confirmPassword: new FormControl('', [Validators.password, Validators.required]),
      });
      this.loaded = true;
    },
    updateLanguage(value: IDropdownOption) {
      this.languageValue = value;
      this.formData.controls.language.value = this.languageValue.value;
      this.setChangesMade();
      this.validateControl('language');
    },
    clearLanguage(value: any) {
      if (value === '') {
        this.languageValue = null;
        this.formData.controls.language.value = null;
        this.setChangesMade();
        this.validateControl('language');
      }
    },
    async profileImageFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.profileImage = [];
      this.loadingHandler({
        type: 'profile-image',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.profileImage.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'profile-image',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'profile-image',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    async signatureFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.signature = [];
      this.loadingHandler({
        type: 'signature',
        overRide: true,
        overRideValue: true,
      });
      try {
        const url = await this.uploadImage(image);
        this.signature.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'signature',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'signature',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    loadingHandler({ type, overRide, overRideValue }: { type: 'profile-image' | 'signature'; overRide: boolean; overRideValue: boolean }): boolean {
      if (type === 'profile-image') {
        if (overRide) {
          return (this.profileImageUploading = overRideValue);
        }
        return (this.profileImageUploading = !this.profileImageUploading);
      } else {
        if (overRide) {
          return (this.signatureUploading = overRideValue);
        }
        return (this.signatureUploading = !this.signatureUploading);
      }
    },
    async readFile(file: Blob): Promise<Jimp> {
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = async (event): Promise<any> => {
          const image: Jimp = await jimp.read(event?.target?.result as string);
          return resolve(image);
        };
        fileReader.readAsDataURL(file);
      });
    },
    setChangesMade() {
      this.changesMade = true;
    },
    setPasswordChangesMade() {
      this.passwordChangesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    validatePasswordControl(controlName: string): void {
      setTimeout(() => {
        this.passwordFormData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setPasswordChangesMade();
        }
      }, 0);
    },
    toggleNewShow(): void {
      this.inputNewType = this.inputNewType === 'password' ? 'text' : 'password';
    },
    toggleConfirmShow(): void {
      this.inputConfirmType = this.inputConfirmType === 'password' ? 'text' : 'password';
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let profileImageUrl = '';
        let signatureUrl = '';

        if (this.profileImage && this.profileImage.length > 0) {
          profileImageUrl = this.profileImage[0].url;
        }

        if (this.signature && this.signature.length > 0) {
          signatureUrl = this.signature[0].url;
        }

        let payload = {
          roleId: this.user?.roleId,
          firstName: this.formData?.controls?.firstName?.value,
          lastName: this.formData?.controls?.lastName?.value,
          email: this.user?.email,
          secondaryEmail: this.user?.secondaryEmail,
          mobileNumber: this.formData?.controls?.mobileNumber?.value,
          officeNumber: this.formData?.controls?.officeNumber?.value,
          companyId: this.user?.companyId,
          title: this.formData?.controls?.title?.value,
          languageId: this.formData?.controls?.language?.value,
          brandIds: this.user?.brands.map((brand: IBrand) => brand.brandId),
          statusId: this.user?.statusId,
          profileImageUrl,
          signatureUrl,
          storeIds: this.user?.stores.map((store: IStore) => store.storeId),
          accessIds: this.user?.dataAccess.map((access: any) => access.dataAccessId),
          permissionIds: this.user?.permissions.map((permission: any) => permission.permissionId),
        };

        this.saving = true;

        try {
          await this.updateUser({ payload, userId: this.user?.userId });
          this.$notify({
            title: 'SUCCESS',
            text: 'User updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update User',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'User form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async reset(): Promise<void> {
      this.passwordFormData.markAllAsTouched();
      if (!this.passwordFormData.hasErrors) {
        let payload = {
          roleId: this.user?.roleId,
          firstName: this.user?.firstName,
          lastName: this.user?.lastName,
          email: this.user?.email,
          secondaryEmail: this.user?.secondaryEmail,
          mobileNumber: this.user?.mobileNumber,
          officeNumber: this.user?.officeNumber,
          companyId: this.user?.companyId,
          title: this.user?.title,
          languageId: this.user?.languageId,
          brandIds: this.user?.brands.map((brand: IBrand) => brand.brandId),
          statusId: this.user?.statusId,
          profileImageUrl: this.user?.profileImageUrl,
          signatureUrl: this.user?.profileImageUrl,
          storeIds: this.user?.stores.map((store: IStore) => store.storeId),
          accessIds: this.user?.dataAccess.map((access: any) => access.dataAccessId),
          permissionIds: this.user?.permissions.map((permission: any) => permission.permissionId),
          password: this.passwordFormData?.controls?.newPassword?.value,
        };

        this.resetting = true;

        try {
          await this.updateUser({ payload, userId: this.user?.userId });
          this.resetting = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'User updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.refreshUser();
        } catch (err) {
          this.resetting = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update User',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'User form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async refreshUser(): Promise<void> {
      this.loaded = false;
      const email = this.user?.email;
      this.updateCurrentUser(null);
      document.cookie = 'jwt=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      const user = await this.authenticate({
        email: email,
        password: this.passwordFormData.controls.newPassword.value,
      });

      this.formData.reset();
      this.passwordFormData.reset();

      if (this.isAuthenticated) {
        this.updateCurrentUser(user);
        window.localStorage.setItem('userId', user.userId);
      }
      this.loaded = true;
    },
    updateRedirectTo(value: IDropdownOption) {
      this.redirectTo = value;
      this.validateControl('redirectTo');
    },
    clearRedirectTo(value: any) {
      if (value === '') {
        this.validateControl('redirectTo');
      }
    },
    ccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).ccUsersValues = value;
    },
    bccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).bccUsersValues = value;
    },
    async updateEmailTemplate(reminderTypeId: number): Promise<void> {
      const emailTemplate = this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId);
      const payload = {
        userId: this.user?.userId,
        reminderTypeId,
        cc: emailTemplate.ccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
        bcc: emailTemplate.bccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
        optIn: emailTemplate.optIn,
      };

      this.saving = true;

      try {
        await this.saveUserEmailTemplate(payload);
        this.$notify({
          title: 'SUCCESS',
          text: 'User Email Template saved successfully!',
          type: 'success',
          duration: 5000,
        });
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to save User Email Template.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    async updateEmailPreferences(): Promise<void> {
      if (this.snooze && !this.redirectTo) {
        this.$notify({
          title: 'ERROR',
          text: 'Please select a redirect to option.',
          type: 'error',
          duration: 5000,
        });
        return;
      }
      const payload = {
        userId: this.user?.userId,
        snooze: this.snooze,
        redirectTo: this.redirectTo?.value,
        redirectEndDate: this.redirectEndDate,
      };

      this.saving = true;

      try {
        await this.saveUserEmailPreference(payload);
        this.$notify({
          title: 'SUCCESS',
          text: 'User Email Preferences saved successfully!',
          type: 'success',
          duration: 5000,
        });
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to save User Email Preferences.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    toggleSnooze() {
      this.snooze = !this.snooze;
      if (!this.snooze) {
        this.redirectTo = null;
        this.redirectEndDate = null;
      }
    },
    toggleOptIn(reminderTypeId: number) {
      const emailTemplate = this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId);
      emailTemplate.optIn = !emailTemplate.optIn;
    },
    populateEmailPreferencesCCs() {
      if (this.user?.emailTemplates) {
        this.user?.emailTemplates?.forEach((emailTemplate: any) => {
          const et = this.emailTemplates.find((template) => template.reminderTypeId === emailTemplate.reminderTypeId);
          let ccUsersValues: any[] = [];
          if (emailTemplate.cc) {
            ccUsersValues = emailTemplate.cc?.split(',').map((cc: string) => {
              return this.ccUsersOptions.find((u: any) => u.value === cc);
            });
            et.ccUsersValues = ccUsersValues;
          }
          setTimeout(() => {
            if (ccUsersValues.length > 0) {
              this.ccUsersUpdated([...ccUsersValues], et?.reminderTypeId);
            } else {
              this.ccUsersUpdated([], et?.reminderTypeId);
            }
          }, 500);
        });
      }
    },
    populateEmailPreferencesBCCs() {
      if (this.user?.emailTemplates) {
        this.user?.emailTemplates?.forEach((emailTemplate: any) => {
          const et = this.emailTemplates.find((template) => template.reminderTypeId === emailTemplate.reminderTypeId);
          let bccUsersValues: any[] = [];
          if (emailTemplate.bcc) {
            bccUsersValues = emailTemplate.bcc?.split(',').map((bcc: string) => {
              return this.bccUsersOptions.find((u: any) => u.value === bcc);
            });
            et.bccUsersValues = bccUsersValues;
          }
          setTimeout(() => {
            if (bccUsersValues.length > 0) {
              this.bccUsersUpdated([...bccUsersValues], et?.reminderTypeId);
            } else {
              this.bccUsersUpdated([], et?.reminderTypeId);
            }
          }, 500);
        });
      }
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if (this.user) {
          this.setForm();
          this.getEmailTemplates('user');
          this.loadCcUsers({ companyId: this.user.companyId, excludeUserId: this.user.userId });
          this.loadBccUsers({ companyId: this.user.companyId, excludeUserId: this.user.userId });
        }
      },
    },
    languageOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.languageOptions.forEach((item: IDropdownOption) => {
          if (this.user?.languageId === item?.value) {
            this.languageValue = item;
          }
        });
      },
    },
    permissionTemplateOptions: {
      deep: true,
      handler() {
      },
    },
    permissionTemplates: {
      deep: true,
      handler() {
      },
    },
    metaEmailTemplates: {
      deep: true,
      handler() {
        const userEmailTemplates = this.user?.emailTemplates;
        this.metaEmailTemplates.forEach((emailTemplate: any) => {
          const userEmailTemplate = userEmailTemplates?.find((template: any) => template.reminderTypeId === emailTemplate.reminderTypeId);
          if (userEmailTemplate) {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              // ccUsersValues: [],
              // bccUsersValues: [],
              optIn: userEmailTemplate.optIn,
            });
          } else {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              ccUsersValues: [],
              bccUsersValues: [],
              optIn: true,
            });
          }
        });
      },
    },
    ccUsersOptions: {
      deep: true,
      handler() {
        this.populateEmailPreferencesCCs();
        if (this.snooze) {
          this.redirectTo = this.ccUsersOptions.find((u: any) => u.value === this.user?.emailPreference?.redirectTo);
        }
      },
    },
    bccUsersOptions: {
      deep: true,
      handler() {
        this.populateEmailPreferencesBCCs();
      },
    },
  },
});
